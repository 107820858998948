import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ExclusiveReport,
  FieldName,
  ReportCreateRequest,
  ReportFormat,
  ReportTypes,
  ReportUpdateRequest,
  UserReport,
} from '@/app/core/models/report.model';
import { map } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { OriginDestinationPair } from '@/app/core/models/user.model';
import { Nullable } from '@/types';

@Injectable()
export class ReportService {
  http = inject(HttpClient);
  authService = inject(AuthService);

  getUserReports(username: string) {
    return this.http.get<UserReport[]>('/api/TowlineUserReports', {
      params: { username },
    });
  }
  getUserReport(reportId: number) {
    return this.http
      .get<UserReport[]>(`/api/TowlineUserReports/${reportId}`)
      .pipe(map(([result]) => result));
  }
  getExclusiveReports(username: string) {
    return this.http.get<ExclusiveReport[]>(
      '/api/TowlineExclusiveReportUserSetup',
      {
        params: { username },
      },
    );
  }
  getOriginDestinationOptions(username: string) {
    return this.http.get<OriginDestinationPair[]>(
      '/api/TowlineOriginsDestinations',
      {
        params: { username },
      },
    );
  }
  generateReport(
    type: ReportTypes,
    format: ReportFormat,
    username: string,
    reportId: string,
    filterId: string,
    originDestinationList: number[],
  ) {
    const body = {
      report_id: Number(reportId), // data-reportid property of the html
      report_type: type,
      report_format: format,
      filter_id: filterId, // selected filter.id from TowlineUser, can be "-1" for no filter or the id of the filter that was selected.
      username: username,
      origin_destination_list: null as Nullable<number[]>,
    };
    if (originDestinationList.length > 0) {
      body.origin_destination_list = originDestinationList;
    }
    return this.http.post<string>('/api/TowlineReport', body);
  }
  getFieldNames(username: string) {
    return this.http
      .get<FieldName[]>('/api/TowlineFieldNames', {
        params: { username },
      })
      .pipe(
        map((fieldNames) =>
          fieldNames.filter((fieldName) => !!fieldName.FRIENDLY_NAME),
        ),
      );
  }
  updateReport(userReportsId: number, report: ReportUpdateRequest) {
    return this.http.put<UserReport>(
      `/api/TowlineUserReports/${userReportsId}`,
      report,
    );
  }
  createReport(report: ReportCreateRequest, username: string) {
    return this.http.post<UserReport>(
      `/api/TowlineUserReports?username=${username}`,
      report,
    );
  }
  deleteReport(reportId: string) {
    return this.http.delete(`/api/TowlineUserReports/${reportId}`);
  }
}
